<template>
  <default-layout class="layout">
    <router-view></router-view>
  </default-layout>

</template>

<script>
import {useMeta} from 'vue-meta'

export default {
  metaInfo: {
    title: '8illion agency',
    titleTemplate: '%s | 8illion agency'
  },
  setup() {
    useMeta({title: '8illion agency'})
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
@import "@/assets/scss/slider.scss";

#service #about #plans #team #contact #vacancy {
  scroll-margin-top: 5rem;
}
</style>
