<template>
  <div class="p-4 xl:p-20">

    <div class="flex justify-between xl:grid hidden">
      <h1 class="text-caption">
        We are hiring
      </h1>
      <div class="md:hidden flex items-end mb-2">
      </div>
    </div>


    <Splide class="block xl:hidden" :has-track="false" aria-label="..."
            :options="{rewind: true, pagination: false, type: 'loop',}">
      <div class="splide__arrows lg:px-16">
        <h1 class="text-caption py-4">
          A lot of jobs to do
        </h1>
        <div class="flex flex-row">
          <button class="splide__arrow splide__arrow--prev">
            <box-icon color="white" size="2.25rem" name='left-arrow-alt'></box-icon>
          </button>
          <button class="splide__arrow splide__arrow--next">
            <box-icon color="white" size="2.25rem" name='right-arrow-alt'></box-icon>
          </button>
        </div>
      </div>
      <SplideTrack>
        <SplideSlide class="our-card" :key="key" v-for="(vacancy, key) in vacancies">
          <div>
            <div>
              <button class="jobs-btn" :key="tag_key" v-for="(tag, tag_key) in vacancy.tags">
                {{ tag }}
              </button>
            </div>

            <h2 class="jobs-text">
              {{ vacancy.position }}
            </h2>
            <p class="our-text-two">
              {{ vacancy.desc }}
            </p>
            <p class="text-white font-bold opacity-50 pt-16">
              Read more &#10132;
            </p>
          </div>
        </SplideSlide>
      </SplideTrack>

    </Splide>


    <div class="jobs-card xl:grid hidden">
      <div class="our-card" :key="key" v-for="(vacancy, key) in vacancies">
        <div>
          <button class="jobs-btn" :key="tag_key" v-for="(tag, tag_key) in vacancy.tags">
            {{ tag }}
          </button>
        </div>

        <h2 class="jobs-text">
          {{ vacancy.position }}
        </h2>
        <p class="our-text-two">
          {{ vacancy.desc }}
        </p>
        <a target="_blank" href="https://sergeirodin_w.t.me/" class="text-white font-bold opacity-50 pt-16">
          Get job &#10132;
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import {defineComponent} from "vue";

defineComponent({
  components: {
    Splide,
    SplideTrack,
    SplideSlide,
  },
});

let vacancies = [
  {
    tags: ['Social', 'Web', 'Design'],
    position: 'Internet Marketer',
    desc: "Your role is the creation and implementation of marketing strategies for internal projects of the company and our customers."
  },
  {
    tags: ['Networking', 'Branding', 'PR'],
    position: 'PR Manager',
    desc: "We are looking for a PR Manager to develop our clients’ communications with media and opinion leaders in the cryptocurrency and technology industry."
  },
  {
    tags: ['Networking', 'Researching', ],
    position: 'Sales Manager',
    desc: "Your role will be to for a bridge between 8illion services and our clients’ needs and business."
  },
]

</script>
<style lang="scss" scoped>
@import "@/assets/scss/slider.scss";
</style>

