<template>
  <div class="p-4 lg:p-20">

    <h1 class="text-caption">
      Services
    </h1>

    <div class="cards flex flex-col lg:flex xl:flex-row xl:justify-between gap-4 xl:gap-4 mb-4">
      <Disclosure class="group xl:hidden block w-full" :key="key" v-for="(item, key) in cards[1]">
        <template #title>
            <img class="card-svg" :src="item.img"/>
            <p class="text-card py-0">
              {{ item.name }}
            </p>
        </template>
        <template #content>
          <div>
            <p class="text-card-two block">
              {{ item.desc }}
            </p>
          </div>
        </template>
      </Disclosure>
      <div class="group card_by_3" :key="key" v-for="(item, key) in cards[1]">
        <div class="card-services">
          <img class="card-svg" :src="item.img"/>
          <div class="flex flex-col justify-between xl:flex xl:flex-col lg:flex lg:flex-col">
            <p class="text-card">
              {{ item.name }}
            </p>
            <p class="text-card-two">
              {{ item.desc }}
            </p>
            <p class="group-hover:bg-white group-hover:text-red-500 text-card-number">
              {{ item.number }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col xl:flex xl:flex-row xl:justify-between mb-4 gap-4 xl:gap-4">
      <Disclosure class="group xl:hidden block w-full" :key="key" v-for="(item, key) in cards[2]">
        <template #title>
          <img class="card-svg" :src="item.img"/>
          <p class="text-card py-0">
            {{ item.name }}
          </p>
        </template>
        <template #content>
          <div>
            <p class="text-card-two block">
              {{ item.desc }}
            </p>
          </div>
        </template>
      </Disclosure>
      <div class="group xl:w-1/2 xl:block hidden" :key="key" v-for="(item, key) in cards[2]">
        <div class="card-services-second">
          <img class="card-svg" :src="item.img"/>
          <div
              class="flex flex-col xl:w-full justify-between sm:flex sm:flex-col sm:justify-between lg:flex lg:flex-col">
            <p class="text-card">
              {{ item.name }}
            </p>
            <p class="text-card-two">
              {{ item.desc }}
            </p>
            <p class="group-hover:bg-white group-hover:text-red-500 text-card-number">
              {{ item.number }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="cards flex flex-col lg:flex xl:flex-row xl:justify-between gap-4 xl:gap-4 mb-4">
      <Disclosure class="group xl:hidden block w-full" :key="key" v-for="(item, key) in cards[3]">
        <template #title>
          <img class="card-svg" :src="item.img"/>
          <p class="text-card py-0">
            {{ item.name }}
          </p>
        </template>
        <template #content>
          <div>
            <p class="text-card-two block">
              {{ item.desc }}
            </p>
          </div>
        </template>
      </Disclosure>

      <div class="group card_by_3" :key="key" v-for="(item, key) in cards[3]">
        <div class="card-services">
          <img class="card-svg" :src="item.img"/>
          <div class="flex flex-col justify-between xl:flex xl:flex-col lg:flex lg:flex-col">
            <p class="text-card">
              {{ item.name }}
            </p>
            <p class="text-card-two">
              {{ item.desc }}
            </p>
            <p class="group-hover:bg-white group-hover:text-red-500 text-card-number">
              {{ item.number }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import Disclosure from './ui/disclosure'

const cards = {
  1: [
    {
      name: "DeFi",
      img: require("@/assets/img/cards/influencers.svg"),
      desc: "Building DeFi concepts from scratch, providing turnkey service, while helping to develop in the world of Web 3.0. Transforming traditional financial products into a reliable and transparent protocol without intermediaries.",
      number: "01"
    },
    {
      name: "Influencers",
      img: require("@/assets/img/cards/defi.svg"),
      desc: "Supplying projects, businesses and brands with reliable contacts and public relations, with the help of opinion leaders in the required field and niches.",
      number: "02"
    },
    {
      name: "STO",
      img: require("@/assets/img/cards/sto.svg"),
      desc: "Offering a more secure way to raise funds publicly and digitally through the asset-backed Security Token Offerings on a blockchain.",
      number: "03"
    },

  ],
  2: [
    {
      name: "IEO/ICO",
      img: require("@/assets/img/cards/ieo.svg"),
      desc: "Launch of a fundraising/distribution of new crypto tokens through the Initial Exchange Offerings, and though the Initial Coin Offerings to investors or the broader public turnkey.",
      number: "04"
    },
    {
      name: "Social Media Management",
      img: require("@/assets/img/cards/social_media.svg"),
      desc: "Using social platforms as channels to promote the brand, increase the target audience, drive traffic to the site and increase sales. Full packaging and project management in any social networks necessary for development, support throughout the entire period of cooperation.",
      number: "05"
    },
  ],
  3: [{
    name: "Community Management",
    img: require("@/assets/img/cards/management.svg"),
    desc: "Building an authentic community among a business's customers, employees, and partners through various types of interaction. Moderation, obtaining feedback and ideas from customers and audience members through real conversations. Providing support. Increasing brand and product awareness among your target audience.",
    number: "06"
  },
    {
      name: "Growth Hacking Packages",
      img: require("@/assets/img/cards/growth.svg"),
      desc: "Implementation of proven \"high-speed\" development methods to increase the speed of development, growth of projects/businesses/brands. Possibility to use several services at once, without additional overpayment, with the chance of extra combining for each individual project",
      number: "07"
    },
    {
      name: "Publisher Houses Marketplace",
      img: require("@/assets/img/cards/publisher.svg"),
      desc: "Possibility to order services from leading opinion leaders in one click, using the best public platforms with millions of users for growth and promotion.",
      number: "08"
    },


  ]
}

</script>
<style lang="scss" scoped>
.card_by_3 {
  @apply hover:w-1/2 hidden xl:block;
  width: 33.3%;
}
.cards:hover > .card_by_3:not(:hover) {
  @apply w-1/4;
}

.card-services {
  @apply group-hover:bg-red-500 transition  ease-in xl:h-[400px] p-4 sm:p-8 xl:p-10
  flex flex-row justify-between sm:flex sm:flex-row sm:justify-between xl:flex xl:flex-col
  xl:justify-between xl:items-start border xl:border-2 border-gray-500 border-opacity-30 rounded-3xl
  bg-gray-500 bg-opacity-10;

}

.card-services-second {
  @apply card-services xl:h-[440px];
}

.card-services-third {
  @apply card-services xl:h-[470px];
}

.card-svg {
  @media (max-width: $xl) {
    opacity: 1 !important;
  }
  @apply group-hover:opacity-100 opacity-20 group-hover:lg:opacity-100 lg:opacity-20
  w-6 h-6 sm:w-8 sm:h-8 lg:w-16 lg:h-16
}

.card-icon {
  @apply pl-2;
}
</style>
