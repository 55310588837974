import App from './App.vue'
import router from './router'
import store from './store'
import DefaultLayout from '@/layouts/DefaultLayout'
import VueSplide from '@splidejs/vue-splide';
import {createApp} from 'vue';
import 'boxicons';
import { createMetaManager } from 'vue-meta'
import './assets/css/tailwind.css'
import '@splidejs/vue-splide/css';

import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

const app = createApp(App)

app.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
})


app.component('DefaultLayout', DefaultLayout)

app.use(SetupCalendar, {})
// Use the components
app.component('V-Calendar', Calendar)
app.component('DatePicker', DatePicker)

app.use(router)
app.use(VueSplide);
app.use(store)
app.use(createMetaManager());

app.mount('#app')
