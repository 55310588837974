<template>
  <div class="base-nav">
    <!-- Mobile navigation -->
    <nav class="nav nav-mobile text-white" :class="{'nav__scroll': scrollPosition > 24}">
      <div class="flex justify-between py-4 sm:px-10 ">
        <div class="pt-1">
          <a href="#" class="logo-mobile">
            <img src="~@/assets/img/logo.svg" width="152" height="24">
          </a>
        </div>
        <div class="flex flex-col space-y-4">
              <span class="burger-button cursor-pointer flex">
                  <p @click="openContactForm()"
                     class="hidden sm:block text-white text-sm font-bold pt-1 pr-8">
                      Reach to us
                  </p>
                  <div @click="openMobileMenu" class="menu_button"></div>
              </span>
        </div>
      </div>
    </nav>

    <!-- Desktop navigation -->
    <nav class="nav nav-desk" :class="{'nav__scroll': scrollPosition > 45}">
      <div class="nav-desk__content flex justify-between">
        <img src="~@/assets/img/logo.svg" width="152" height="24">

        <ul class="flex justify-between">
          <a href="#about" class="nav-list-desk__item">About Us</a>
          <a href="#service" class="nav-list-desk__item">Services</a>
          <a href="#plans" class="nav-list-desk__item">Plans</a>
          <a href="#team" class="nav-list-desk__item">Team</a>
          <!--          <a href="#service" class="nav-list-desk__item">Portfolio</li>-->
          <a href="#vacancy" class="nav-list-desk__item">Jobs</a>
          <button class="text-white font-bold rounded-3xl px-6 py-3 mr-16" @click="openContactForm()">
            Reach to us
          </button>
        </ul>
      </div>
    </nav>

    <Modal ref="menuModal" v-model="isMobileMenuOpen">
      <template #title>
        <h3 class="menu__title">
          Menu
        </h3>
      </template>
      <template #content>
        <div class="grid grid-cols-1 content-between">
        <ul class="text-white text-xl flex flex-col mt-14">
          <li @click="goToBlock('about')" class="nav-mobile-item">About Us</li>
          <li @click="goToBlock('service')" class="nav-mobile-item">Services</li>
          <li @click="goToBlock('plans')" class="nav-mobile-item">Plans</li>
          <li @click="goToBlock('team')" class="nav-mobile-item">Team</li>
          <li @click="goToBlock('vacancy')" class="nav-mobile-item">Jobs</li>
        </ul>
        <button @click="openContactForm()"
                class="text-white text-sm font-bold border-2 border-gray-400
                                        rounded-3xl px-6 py-2 mt-10">
          Reach to us
        </button>
    </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import Modal from "@/components/ui/Modal";
import {onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {openContactForm} from "@/components/forms"
import {scrollIntoView} from "seamless-scroll-polyfill";

const menuModal = ref(null)
const isMobileMenuOpen = ref(false)
let toBlock = null

watch(isMobileMenuOpen, async (current, old) => {
  if (!current && old && toBlock) {
    const el = document.getElementById(toBlock)
    toBlock = null
    setTimeout(() => {
      scrollIntoView(el)
    }, 1000)
  }
})

onBeforeMount(() => {
  window.addEventListener('scroll', updateScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', updateScroll);
})

const scrollPosition = ref(0)

function openMobileMenu() {
  isMobileMenuOpen.value = true
}

function goToBlock(blockName) {
  menuModal.value.closeModal();
  toBlock = blockName
}

function updateScroll() {
  scrollPosition.value = window.scrollY
}
</script>
<style lang="scss">
@keyframes toBottom {
  from {
    top: -30px;
  }
  to {
    top: 60px;
  }
}

@keyframes toBottomMobile {
  from {
    top: -60px;
  }
  to {
    top: 30px;
  }
}


.nav {
  @apply absolute left-0 right-0 z-50;
}

.nav-desk {
  top: 60px;
  @apply lg:block hidden w-full px-4;
  animation: 1s toBottom ease;
}

.nav-desk__content {
  @apply px-20 m-auto;
  max-width: 1440px;
}

.nav-list-desk__item {
  @apply text-gray-100 text-sm pr-6 pt-3;
}

.nav-mobile {
  top: 30px;
  @apply px-8 sm:pt-4 lg:hidden w-full;
  animation: 1s toBottomMobile ease;
}

.nav-mobile-item {
  @apply pb-8 cursor-pointer;
}

.nav__scroll {
  @apply bg-black opacity-100 py-2 z-40;
  position: fixed !important;
  top: 0 !important;
}

.nav__modal {
  margin-top: 0.5rem;
}

.menu__title {
  @apply text-white text-xl font-bold;

}

.menu_button {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("@/assets/img/nav/menu_button.svg");
  width: 48px;
  height: 32px;
  opacity: 0.8;
}

.menu_button:hover {
  opacity: 1 !important;
}

.menu_button-active {
  background-image: url("@/assets/img/nav/menu_button_active.svg");

}

</style>
