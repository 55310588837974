<template>
  <div>
    <Spinner v-model="isSpinner"/>
    <NavBar/>
    <slot/>
    <FooterComponent/>
  </div>
</template>
<script setup>
import NavBar from "@/components/base/NavBar";
import FooterComponent from "@/components/base/Footer";
import Spinner from "@/components/ui/Spinner";
import {ref} from 'vue';

let isSpinner = ref(false)

</script>
