<template>
  <div class="p-4 xl:p-20">

    <div class="xl:grid hidden">
      <h1 class="text-caption">
        Our team
      </h1>
    </div>

    <Splide class="block xl:hidden" :has-track="false" aria-label="..." :options="{rewind: true, pagination: false}">
      <div class="splide__arrows lg:px-16">
        <h1 class="text-caption">
          Our team
        </h1>
        <div class="flex flex-row">
          <button class="splide__arrow splide__arrow--prev">
            <box-icon color="white" size="2.25rem" name='left-arrow-alt'></box-icon>
          </button>
          <button class="splide__arrow splide__arrow--next">
            <box-icon color="white" size="2.25rem" name='right-arrow-alt'></box-icon>
          </button>
        </div>
      </div>
      <SplideTrack class="space-x-5">
        <SplideSlide class="our-card" :key="key" v-for="(person, key) in teams">
          <div class="our-card__image"  :style="{'background-image': `url(${person.image})`}" />
          <h2 class="our-text-one">{{ person.name }}</h2>
          <p class="our-text-two">{{ person.desc }}</p>
          <div class="pt-6 flex">
            <button class="our-btn" :key="position_key" v-for="(position, position_key) in person.positions">
              {{ position }}
            </button>
          </div>
        </SplideSlide>
      </SplideTrack>

    </Splide>


    <div class="jobs-card xl:grid hidden">
      <div class="our-card" :key="key" v-for="(person, key) in teams">
        <div  class="our-card__image"  :style="{'background-image': `url(${person.image})`}" />

        <h2 class="our-text-one">{{ person.name }}</h2>
        <p class="our-text-two">{{ person.desc }}</p>
        <div class="pt-4 flex">
          <button class="our-btn" :key="position_key" v-for="(position, position_key) in person.positions">
            {{ position }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import {defineComponent} from "vue";

defineComponent({
  components: {
    Splide,
    SplideTrack,
    SplideSlide,
  },
});

let teams = [
    {
  positions: ['CEO',],
  name: "Sergei Rodin",
  desc: "",
  image: require("@/assets/img/avatar/avatar_1.png")
  },
  {
    positions: ['COO',],
    name: "Anastassia Morozova",
    desc: "",
    image: require("@/assets/img/avatar/avatar_2.jpeg")
  },
  {
    positions: ['Chief Investment Officer',],
    name: "Irina Valtfogel",
    desc: "",
    image: require("@/assets/img/avatar/avatar_4.jpg")
  },
  {
    positions: ['Chief Marketing Officer',],
    name: "Andrii Buznia",
    desc: "",
    image: require("@/assets/img/avatar/avatar_7.jpg")
  },
  {
    positions: ['CPO',],
    name: "Heinrich Kerner",
    desc: "",
    image: require("@/assets/img/avatar/avatar_5.jpeg")
  },
  {
    positions: ['Chief Relationship Officer',],
    name: "Alexander Krez",
    desc: "",
    image: require("@/assets/img/avatar/avatar_6.jpeg")
  },
  {
    positions: ['CTO',],
    name: "Paul Voit",
    desc: "",
    image: require("@/assets/img/avatar/avatar_3.jpg")
  },
]

// function getImgUrl(pic_url) {
//   return require(pic_url)
// }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/slider.scss";

.our-card__image {
  @apply h-24 w-24 bg-cover bg-center bg-no-repeat rounded-2xl grayscale;
}

</style>

