<template>
  <div class="grid grid-cols-4 grid-flow-row gap-1 mx-4 mb-3">
    <button :key="key" v-for="(value, key) in meetingTimes"
         @click="selectMeeting(key)"
         class="time"
        :class="[timeClasses(value),]">
      {{ formatTime(value.date) }}
    </button>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits,computed} from 'vue';
const props = defineProps({currentDate: Date, openHour: Number, closeHour: Number, interval: Number, modelValue: Date})
const emit = defineEmits(['update:modelValue'])

let selectedTimeKey = ref(null)

const meetingTimes = computed(() => {
  if (!props.currentDate) return

  let currentDate = Date.now()
  let selectedDate = new Date(props.currentDate.setHours(0, 0, 0))
  let start = new Date(selectedDate.setHours(props.openHour))
  let end = new Date(selectedDate.setHours(props.closeHour))
  let slices = []

  while (end >= start) {
    const meetingKey = slices.length;
    slices[meetingKey] = {date: new Date(start), isActive: currentDate < start,
      isSelected: !!props.modelValue && start.getTime() === props.modelValue.getTime()};
    start.setMinutes(start.getMinutes() + props.interval)
  }
  return slices
});

function timeClasses(value){
  if (!value.isActive) {
    return 'time__disable'
  }
  return value.isSelected ? 'time__selected' : ''
}

function formatTime(dateToFormat) {
  return dateToFormat.toString().slice(16,21);
}

function selectMeeting(timeKey) {
  const value = meetingTimes.value[timeKey]
  if (!value.isActive) return
  emit('update:modelValue', value.date)
  selectedTimeKey.value = timeKey
}

</script>
<style lang="scss">
.time {
  @apply text-gray-300 px-3 py-1 rounded-xl shadow-2xl ;
  background-color: #1B1B1B;
  @apply border xl:border-2 border-gray-500 border-opacity-30;

  &:hover{
    @apply text-white bg-blue-900;
  }

  &__disable {
    background-color: #505050;
  }

  &__selected {
    @apply text-white bg-blue-600;
  }

}

</style>
