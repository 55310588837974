<template>
  <div class="pt-14 sm:py-14 sm:px-4 lg:p-20 w-full">
    <div class="text-white opacity-50 flex flex-col justify-between items-center
                md:flex md:flex-row md:justify-between">

      <img class="pb-9 md:pb-0 lg:pb-0" src="@/assets/img/logo.svg" width="152" height="24">

      <ul class="text-sm flex flex-col justify-between items-center md:flex md:flex-row md:justify-between sm:gap-4 lg:gap-6">
<!--        <a target="_blank" href="https://twitter.com/m3tav3rs3101" class="foot-list">Twitter</a>-->
<!--        <a target="_blank" href="https://www.linkedin.com/company/8illion-io" class="foot-list">Linkedin</a>-->
<!--        <a href="https://instagram.com/8illion.io" class="foot-list">Instagram</a>-->
      </ul>
      <p class="py-10 text-xs xl:text-sm">
        © 8illion CO, All rights reserved
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style lang="scss">
  .foot-list {
    @apply pb-6 sm:pb-0 md:pb-0;
  }
</style>
