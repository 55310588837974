<template>
  <div class="mt-11 md:mt-36">
    <div class="relative">
      <svg class="absolute top-0 right-0" width="1045" height="2" viewBox="0 0 1045 2" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.1" d="M0 1H1045" stroke="white"/>
      </svg>
    </div>

    <div class="p-4 sm:pl-48 lg:pl-80 lg:pr-20">
      <h1 class="text-caption">
        About us:
      </h1>
      <p class="text-white text-base sm:text-xl lg:text-2xl font-medium lg:pt-14">
        We are a united group of media buyers, arbitrageurs and engineers with over 10 years of experience in their fields.
      </p>
      <p class="text-white text-sm md:text-base text-opacity-50 pt-4 md:pt-6">
        Our goal is to build the best CPA network in the world market and provide
        our partners with not only the best offers and traffic, but also the best
        technical ecosystem and solutions to achieve the highest results.
      </p>
    </div>
  </div>
</template>
<script>
export default {}
</script>
