<template>
  <div class="plan">

    <h1 class="text-white text-3xl md:text-6xl text-center font-medium px-8 pt-6 lg:pt-14">
      Plans We OFFER
    </h1>

    <div class="grid grid-cols-1 md:grid md:grid-cols-2 pt-6 lg:pt-14 px-7 xl:px-40 gap-2 pb-6 lg:pb-10">
      <div class="card-plans" v-for="(plan, key) in plans" :key="key">
        <p class="card__title"
           :class="[`text-${plan.colorTitle ? plan.colorTitle : plan.color}`]">{{ plan.title }}
        </p>
        <div class="text-white pt-5 flex">
          <p class="card__price">{{ plan.price }}</p>
          <p class="text-xs sm:text-sm lg:text-lg opacity-40 pl-2 pt-2 sm:pt-3 font-inter">{{ plan.priceType }}</p>
        </div>
        <div class="card-text-plans">
          <p class="card__item" v-for="(item, key) in plan.items" :key="key">+ {{ item }}</p>
          <p v-if="plan.additionalData" class="text-xs md:text-base opacity-40 pt-6 lg:pt-8 font-light">
            {{ plan.additionalData }}
          </p>
        </div>
        <div class="pt-6">
          <button @click="openContactForm" class="card-plans-btn" :class="[`bg-${plan.color}`]">
            Purchase
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script setup>
import {openContactForm} from "@/components/forms"

const plans = [{
  title: "Shilling",
  price: "$2000",
  priceType: '/per month',
  items: ['Daily based reports', 'Organic accounts', 'Every time fresh databases', 'Free CRM', 'Keyword shiling'],
  additionalData: 'We work at Telegram, Discord, Twitter, Youtube, Others (can be discussed)',
  color: 'purple-400'
},
  {
    title: "PR",
    price: "$10000",
    priceType: '/per month',
    items: ['5 organic publications per month', 'Overviews', 'Interviews', 'Online speeches',
      'From tier 3 to tier 3 media', 'Large database for paid marketing publications'],
    color: 'orange-500'
  },
  {
    title: "KOL's",
    price: "10%",
    priceType: 'Budget fee',
    items: ['Trusted traffic channels', 'Worldwide auditory', 'Only high rated influencers', 'Short delivery time',
      'Personal strategy included'],
    colorTitle: 'yellow-500',
    color: 'yellow-600'
  },
  {
    title: "SMM service",
    price: "$3500",
    priceType: '/per month',
    items: ['We cover all social media', 'Professional content writers', 'Professional designers',
      '20+ posts per month', 'Unique content', '24/7 Support', 'Context/Targeting traffic'],
    colorTitle: 'blue-500',
    color: 'blue-600'
  },
]


</script>

<style lang="scss">
.plan {
  @apply bg-no-repeat bg-cover mt-12 xl:p-4 rounded-3xl;
  background-image: url("~@/assets/img/plans/desktop_bg_1x.png");
  background-image: url("~@/assets/img/plans/mobile_bg_1x.png");
  }
.card-plans-btn {
  @apply text-white text-sm lg:text-base font-bold rounded-3xl px-6 sm:px-8 lg:px-10 py-3 md:py-4;
}
.card__title {
  font-family: 'Unbounded', serif;
  @apply text-sm sm:text-base lg:text-2xl font-medium;
}

.card__price {
  font-family: 'Unbounded', serif;
  @apply text-xl sm:text-3xl lg:text-4xl font-medium;
}

.card__item {
  font-family: 'Unbounded', serif;
  @apply pb-3 md:pb-4;
}
</style>
