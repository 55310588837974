import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'


const routes = [
    {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
            layout: 'DefaultLayout',
            title: "8illion Agency"
        },
    },
]


const router = new createRouter({
    history: createWebHistory(),
    routes
});
export default router
