<template>
  <div class="p-4 m-auto border-red-500" :class="isError ? 'border-b xl:border-b-2 ' : ''">
    <V-Calendar
        :columns="1"
        :min-date="minDate"
        :max-date="maxDate"
        :disabled-dates =disabledDates
        :first-day-of-week="2"
        color="gray"
        is-dark
        mode="date"
        @dayclick="onDayClick"
        v-model="date" is-expanded :attributes="attrs">
      <template #footer>
        <MeetingSelector v-model="resultDate" :current-date="date" :open-hour="8" :close-hour="20" :interval="60" />
      </template>
    </V-Calendar>
  </div>
</template>

<script setup>
import MeetingSelector from "@/components/MeetingSelector";
import {computed, defineEmits, defineProps, ref} from 'vue';

const props = defineProps(['modelValue', 'isError'])
const emit = defineEmits(['update:modelValue'])

const resultDate = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const date = ref();
const minDate = new Date(Date.now());
const maxDate = new Date(minDate).setMonth(minDate.getMonth() + 2)
const disabledDates = [{ weekdays: [1, 7] }]

const attrs = computed(() => [
  {
    highlight: {
      color: 'blue',
      fillMode: 'light',
    },
    dates: resultDate.value,
  },
])

function onDayClick(day) {
  date.value = day.isDisabled ? null : day.date;
}

</script>
<style lang="scss">
.vc-container.vc-is-dark {
  background-color: #141414 !important;
  border: none !important;
  @apply bg-gray-700;
}

</style>
