import {useContactFormStore} from "@/store/modules/contactForm";


export function openContactForm() {
    const contactFormStore = useContactFormStore()
    contactFormStore.setValue(true)
}

export function openReservationContactForm() {
    const contactFormStore = useContactFormStore()
    contactFormStore.setValue(true)
}

