<template>
  <Modal ref="modal" v-model="isOpen">
    <template #title>
      <h3 class="form__title">
        Contact Us
      </h3>
    </template>

    <template #content>
      <div class="flex flex-col xl:flex-row xl:mt-24 items-center w-full justify-center xl:space-x-4 space-y-4">
        <div class="w-full lg:w-1/3 2xl:w-1/4">
          <Calendar v-model="formData.date" :is-error="errors.date" @click="event => errors.date = undefined"
          />
        </div>
      <div class="w-2/3 lg:w-1/3">
      <div class="modal__box-form  form" :class="{'form__error': errors.name}">
        <label class="form__label">Name</label>
        <input type="text" v-model="formData.name" class="form__input"
               placeholder="Your name" required @focus="event => errors.name = undefined">
      </div>

      <div class="modal__box-form  form" :class="{'form__error': errors.email}">
        <label class="form__label">Email</label>
        <input type="text" v-model="formData.email" class="form__input"
               placeholder="username@google.com" required @focus="event => errors.email = undefined">
      </div>

      <div class="modal__box-form  form" :class="{'form__error': errors.telegram}">
        <label class="form__label">Telegram</label>
        <input type="text" v-model="formData.telegram" class="form__input"
               placeholder="@example" required @focus="event => errors.telegram = undefined">
      </div>

      <div class="modal__box-form  form" :class="{'form__error': errors.website}">
        <label class="form__label">Website</label>
        <input type="text" v-model="formData.website" class="form__input"
               placeholder="Your website adress" required @focus="event => errors.website = undefined">
      </div>
      </div>
      </div>
      <button @click="sendForm"
              class="text-gray-400 border-2 border-gray-500 border-opacity-30 hover:text-white hover:bg-blue-600 text-sm flex items-center font-bold
                          rounded-3xl px-6 py-3 my-10 mb-28 xl:mt-20">
        Empower my project
      </button>
    </template>
  </Modal>
</template>

<script setup>
import Calendar from "@/components/Calendar";
import Modal from "@/components/ui/Modal";
import {useVuelidate} from '@vuelidate/core'
import {required, email, url} from '@vuelidate/validators'
import axios from 'axios';
import {useContactFormStore} from '@/store/modules/contactForm'
import {computed, reactive} from "vue";

let formData = reactive({...getInitFromData()})

let rules = {
  name: {required},
  email: {required, email},
  website: {url},
  date: {required}
}

let errors = reactive({})
let validator = useVuelidate(rules, formData)
const store = useContactFormStore()

const isOpen = computed({
  get() {
    return store.isOpen
  },
  set(value) {
    store.setValue(value)
  }
})

function getInitFromData() {
  return {
    name: null,
    email: null,
    telegram: null,
    website: null,
    date: null,
  }
}

async function sendForm() {
  let hasValidate = await validator.value.$validate()
  validator.value.$errors.forEach((value) => {
    errors[value.$property] = value.$message
  })
  if (hasValidate) {
    let requestFormData = {...formData}
    requestFormData['date'] = formData.date.toLocaleString("ru", {timeZone: "Europe/Moscow"})

    await axios.post('https://notify.8illion.io/notify', requestFormData)

    Object.entries(getInitFromData()).forEach(([key, val]) => {
      formData[key] = val
    })
    isOpen.value = false;
  }
}


</script>
<style lang="scss">
.form-color {
  background-color: #1B1B1B;
}

.form {
  @apply flex flex-col items-start font-inter rounded-2xl mb-6 pl-6 py-2 border lg:border-2 border-opacity-30 form-color;
  border-color: rgba(255, 255, 255, 0.03);

  &__title {
    @apply text-white text-xl font-bold;
    @media (min-width: $lg) {
      font-size: 32px;
    }
  }

  &__label {
    @apply text-gray-300 text-xs pb-2;
  }

  &__input {
    @apply focus:outline-none focus:ring-0 font-unbounded text-white text-sm block w-full form-color;
  }

  &__error {
    @apply border lg:border-2 rounded-2xl border-red-600 border-opacity-50;
  }
}

.modal__box-form {
  width: 100%;
  max-width: 500px;

  @media (max-width: $lg) {
    width: 100%;
  }
}

</style>
