<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-opacity-25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div
            class="flex min-h-full items-center justify-center text-center"
        >
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <DialogPanel
                class="modal__box"
            >
              <div class="modal__box-block">
                <div class="modal__box-menu">
                  <slot name="title" v-bind:open="openModal"></slot>
                  <button class="modal__box-close" @click="closeModal"></button>
                </div>
                <div class="modal__box-content">
                  <slot name="content"></slot>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {computed, defineExpose, defineProps, defineEmits} from 'vue'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from '@headlessui/vue'


const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const isOpen = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

defineExpose({isOpen, openModal, closeModal})

function closeModal() {
  isOpen.value = false
}

function openModal() {
  isOpen.value = true
}

</script>
<style lang="scss">
.modal__box {
  @apply transform shadow-xl transition-all relative p-2 md:p-4 w-full h-screen;
}

.modal__box-block {
  @apply h-full rounded-3xl overflow-y-scroll shadow-gray-800 shadow;
  background: #141414;
}

.modal__box-menu {
  @apply px-6 pt-8 lg:px-16 flex items-start justify-between;
  @media (min-width: $lg) {
    padding-top: 70.5px;
  }

}

.modal__box-content {
  @apply flex flex-col items-center justify-center w-full;
}


.modal__box-close {
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.7;
  background-image: url("@/assets/img/nav/menu_button_active.svg");
  width: 48px;
  height: 32px;
  @media (min-width: $lg) {
    width: 80px;
    height: 48px;
  }

}

.modal__box-close:hover {
  opacity: 1;
}
</style>
