import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useContactFormStore = defineStore('contactForm', () => {
    const isOpen = ref(false)

    function setValue(value) {
        isOpen.value = value
    }

    return {isOpen, setValue}

})

export const useReservationFormStore = defineStore('contactForm', () => {
    const isOpen = ref(false)

    function setValue(value) {
        isOpen.value = value
    }

    return {isOpen, setValue}

})
