<template>
  <div class="loader" :class="{'loader__hidden': isLoader}">
    <h1 class="loader-text">{{ counter }}</h1>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted, ref} from 'vue'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])
let isLoader = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

let counter = ref(0)

function toHidden(time) {
  const fn = () => {
    isLoader.value = true
  }
  setTimeout(fn, time)
}

function countUp(max, time) {
  let step = time / max;
  let fn = () => {
    if (counter.value + step > max) {
      counter.value = max
    } else {
      counter.value += 1;
    }
    if (counter.value < max) {
      setTimeout(fn, step);
    }
  }
  fn();
}

onMounted(() => {
  countUp(100, 1500)
  toHidden(2000)
})
</script>
<style>
.loader {
  @apply bg-black overflow-hidden flex;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  transition: opacity 1s;
  transform: translate(0);
  opacity: 1;
}

.loader__hidden {
  transform: translate(9999px);
  @apply opacity-0 hidden;
}

.loader-text {
  @apply text-white text-6xl;
  animation: 2s loadingText ease-in-out;
  transition: all 2s ease-in-out;
}

@keyframes loading {

}

@keyframes loadingText {
  0% {
    @apply mb-52;
    opacity: 0;
  }
  80% {
    opacity: 0.4;
  }
  100% {
    @apply mb-0;
    opacity: 1;
  }
}

</style>
